.d3-chart-bubble-matrix .bubble circle {
  stroke: #fff;
}
.d3-chart-bubble-matrix .thread {
  stroke: #e0e0e0;
}
.d3-chart-bubble-matrix text {
  fill: #666;
  font-size: 80%;
}
